<template>
  <div class="benefits" :class="{ 'benefits--border': border }">
    <div class="benefits__item">
      <IconComponent name="user-circle-add" />
      <span class="benefits__item-title">
        <a @click.prevent="showLoginModal">Войти</a> по номеру <br />
        телефона или email
      </span>
    </div>
    <div class="benefits__item" v-for="(item, i) in benefits" :key="i">
      <IconComponent :name="item.icon" />
      <span class="benefits__item-title" v-html="item.title"></span>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
export default {
  name: "BenefitsComponent",
  components: { IconComponent },
  props: {
    border: Boolean,
  },
  data() {
    return {
      benefits: [
        {
          title:
            "Удобно получить в <br/> <a href='https://orthocity.ru/static/2' target='_blank'>Почте России</a> или <a href='https://orthocity.ru/static/2' target='_blank'>СДЭК</a>",
          icon: "box",
        },
        {
          title:
            "<a href='https://orthocity.ru/static/2' target='_blank'>Бесплатная</a> доставка <br/> при заказе от 10000 р.",
          icon: "truck-fast",
        },
        {
          title:
            "<a href='https://orthocity.ru/shops' target='_blank'>Салоны</a> с приемом <br/> врача ортопеда",
          icon: "location-add",
        },
        // {
        //   title: "Покупай сейчас - плати <a href='https://orthocity.ru/static/1' target='_blank'>потом</a>",
        //   icon: "bag-timer",
        // },
      ],
    };
  },
  methods: {
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {
          routeAfterLogin: {
            name: this.$route.name,
            params: this.$route.params,
            hash: this.$route.hash,
          },
        },
      });
    },
  },
  computed: {
    isRegister() {
      return this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
};
</script>

<style lang="stylus">
.benefits {
  display grid
  grid-template-columns repeat(4, minmax(230px, 1fr));
  grid-gap var(--gap)
  overflow auto visible
  height auto
  min-height 100px
  +below(1400px) {
    gap: 15px
  }
  +below(620px) {
    grid-template-columns repeat(5, minmax(180px, 1fr));
  }

  &--border &__item {
    border-color var(--border-color)
  }

  &__item {
    background: var(--white)
    border 1px solid var(--white)
    border-radius: var(--big-radius)
    padding 15px
    display flex
    align-items center
    gap: 10px
    font-size: 0.875em
    line-height: 20px;
    max-height 80px
    +below(640px) {
      padding 10px
      font-size: 0.75em
      line-height: 14px;
      gap: 0
    }

    .icon {
      width 50px
      height 50px
      padding: 5px
      flex-shrink 0
      +below(620px) {
        width 40px
        height 40px
      }
    }

    a {
      color var(--red)
    }
  }
}
</style>
